import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import { setPositions } from '../actions';
import { getPositions } from '../api';

function* fetchPositions() {
  try {
    const response = yield call(getPositions);
    const orderedPositions = response.data
      ? response.data.sort((p1, p2) => {
          if (p1.department < p2.department) return -1;
          if (p1.department > p2.department) return 1;

          return 0;
        })
      : [];

    yield put(setPositions(orderedPositions));
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default fetchPositions;
