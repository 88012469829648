const MODAL_NAMES = {
  DOWNLOAD_EXTENSION: 'download_extension',
  REINSTALL_EXTENSION: 'reinstall_extension',
  CHAT_WITH_US: 'chat_with_us',
  SEARCH: 'search',
  STORE_INFO: 'store_info',
  MANAGE_STORES: 'manage_stores',
  DELETE_ACCOUNT: 'delete_account',
  PRODUCT: 'product',
  X_PLATFORM: 'x-platform',
  GLOBAL_SEARCH_TOOLTIP: 'global_search_tooltip',
};

export default MODAL_NAMES;
