export const MODAL_TYPES = {
  authenticate: 'authenticate',
  bonusWelcome: 'bonus_welcome',
  completeSignUp: 'complete_sign_up',
  congratsGuest: 'congrats_guest',
  couponList: 'coupon_list',
  listCreate: 'list_create',
  listDelete: 'list_delete',
  listEdit: 'list_edit',
  listShare: 'list_share',
  logIn: 'log_in',
  paypalConfirm: 'paypal_confirm',
  paypalConnect: 'paypal_connect',
  paypalDisconnect: 'paypal_disconnect',
  partnershipContact: 'partnership_contact',
  // resetPassword: 'reset_password',
  resetPasswordNew: 'reset_password_new',
  signUp: 'sign_up',
  guestNotifications: 'guest_notifications',
  tagCreate: 'tag_create',
  tagsAddToList: 'tags_add_to_list',
  tagsDelete: 'tags_delete',
  tagsFulfill: 'tags_fulfill',
  tagsMute: 'tags_mute',
  underMaintenance: 'under_maintenance',
  withdrawCongrats: 'withdraw_congrats',
  doNotSellMyInfo: 'do_not_sell_my_info',
  videoHowToSave: 'video_how_to_save',
  changeLanguage: 'change_language',
  product: 'product',
  itemSaved: 'item_saved',
  congratulateCompleteSignUp: 'congratulate_complete_sign_up',
  chatWithUs: 'chatWithUs',
  storeInfo: 'storeInfo',
  manageStores: 'manageStores',
  deleteAccount: 'deleteAccount',
};

export const SIGN_UP_MODAL_TYPES = [
  MODAL_TYPES.authenticate,
  MODAL_TYPES.signUp,
  MODAL_TYPES.completeSignUp,
  MODAL_TYPES.congratulateCompleteSignUp,
  MODAL_TYPES.logIn,
];

export const CHAT_WITH_US_WINDOW_PARAM = 'chatWithUsWindowParam';
