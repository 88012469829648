import { all, fork } from 'redux-saga/effects';
import retailer from './retailer/saga';
import alerts from './alerts/saga';
import myItems from './myItems/store/sagas';
import user from './user/saga';
import lists from './lists/saga';
import categories from './categories/saga';
import retailers from './retailers/saga';
import retailersGroups from './retailersGroups/saga';
import retailersGroupsTitles from './retailersGroupsTitles/saga';
import notifications from './notifications/saga';
import coupons from './coupons/saga';
import favoriteRetailers from './favoriteRetailers/saga';
import newSales from './newSales/saga';
import onboarding from './onboarding/saga';
import allCoupons from './allCoupons/saga';
import features from './features/saga';
import multiEdit from './multiEdit/saga';
import wallet from './wallet/saga';
import tag from './tag/saga';
import shop from './shop/saga';
import share from './share/saga';
import openPositions from './openPositions/saga';
import landingPage from './landingPage/saga';
import globalSearch from './globalSearch/saga';

export default function* () {
  yield all([
    retailer(),
    alerts(),
    fork(myItems),
    user(),
    lists(),
    categories(),
    retailers(),
    retailersGroups(),
    retailersGroupsTitles(),
    notifications(),
    coupons(),
    favoriteRetailers(),
    newSales(),
    onboarding(),
    allCoupons(),
    features(),
    multiEdit(),
    wallet(),
    tag(),
    shop(),
    share(),
    openPositions(),
    landingPage(),
    globalSearch(),
  ]);
}
