import styled from 'styled-components';
import { Stack } from '@karma/components';
import { getColor } from '@karma/components/colors';
import Badge from 'components/atoms/Badge/Badge';
import TagImage from 'components/atoms/TagImage/TagImage';

export const Link = styled.a`
  display: flex;
  align-items: center;
`;

export const StyledTagImage = styled(TagImage)`
  height: 380px;
  width: 380px;
  border-radius: ${({ rounded }) => (rounded ? '10px' : 0)};
`;

export const Column = styled.div`
  min-width: 380px;
  width: 380px;
  position: relative;

  &.content {
    display: flex;
    justify-content: center;
  }

  & .swiper-container-horizontal {
    border-radius: 10px;
  }

  & .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    background: ${getColor('neutral4')};
    opacity: 1;
    backdrop-filter: blur(20px);

    &.swiper-pagination-bullet-active {
      background: ${getColor('primary1')};
    }
  }

  .swiper-button-prev {
    left: 12px;
  }

  .swiper-button-next {
    right: 12px;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    font-size: inherit;
  }

  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after,
  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after {
    content: none;
  }
`;

export const IconWrapper = styled.div`
  top: 53%;
  transform: translateY(-50%) rotate(-90deg);
  width: 20px;
  height: 20px;
  background: transparent;
  color: ${getColor('primary1')};
  cursor: pointer;
`;

export const BadgeWrapper = styled(Badge)`
  position: absolute;
  top: 20px;
  left: 0px;
  z-index: 1;
`;

export const ButtonsContainer = styled(Stack)`
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 1040;
`;
