import { all, takeLatest } from 'redux-saga/effects';
import { fetchPositions, fetchPosition } from '../actions';
import fetchPositionsWorker from './worker.fetchPositions';
import fetchPositionWorker from './worker.fetchPosition';

function* openPositionsSaga() {
  yield all([takeLatest(fetchPositions, fetchPositionsWorker), takeLatest(fetchPosition, fetchPositionWorker)]);
}

export default openPositionsSaga;
