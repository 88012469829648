const SOURCE_PAGES = {
  SWE: 'safari_web_extension',
  GET_THE_BUTTON: 'get_the_button',
  CAREERS: 'careers',
  HOME: 'home',
  NEW_SALES: 'new_sales_see_all',
  PROFILE: 'profile',
  PROFILE_WALLET: 'profile_karma_cash',
  PROFILE_ACCOUNT_SETTINGS: 'profile_account_settings',
  HOW_IT_WORKS: 'how_it_works',
  OAUTH: 'oauth',
  REDIRECT: 'redirect',
  WISHLIST: 'wishlist',
  COUPONS: 'coupons',
  COUPONS__NEWEST: 'coupons_see_all_newest',
  COUPONS__POPULAR: 'coupons_see_all_popular',
  ITEMS: 'items',
  SORT_BY: 'sort_by',
  STORES: 'stores',
  STORES__FEATURED: 'retailers_see_all_featured',
  STORES__TRENDING: 'retailers_see_all_trending',
  STORES__WE_ARE_LOVING: 'retailers_see_all_we_are_loving',
  CASHBACK_PICKS: 'retailers_see_all_cashback',
  DESIGNATED_STORE: 'designated_store',
  LANDING_AD_1: 'landing_ad_1',
  LANDING_AD_2: 'landing_ad_2',
  LANDING_AD_3: 'landing_ad_3',
  EXTENSION_DELETED: 'extension_deleted',
  MOBILE_LOGIN: 'login_link_mobile_lp',
  LANDING: 'landing',
  LANDING_MAIN: 'landing_main',
  LANDING_MAIN_MOBILE: 'landing_main_mobile',
  LANDING_MAIN_OLD: 'landing_main_old',
  LANDING_MAIN_JOIN: 'landing_main_join',
  LANDING_MAIN_MEN: 'landing_main_men',
  LANDING_MAIN_MEN_MOBILE: 'landing_main_men_mobile',
  LANDING_LP: 'landing_lp',
  LANDING_PAGE_NEW: 'landing_page',
  SHOP: 'shop',
  SHOP_PRODUCT: 'shop_product',
  NOT_FOUND: 'not_found',
  CONTACT: 'contact',
  ABOUT_US: 'about_us',
  TERMS: 'terms',
  PRIVACY: 'privacy',
  PARTNERSHIPS_CONTACT: 'partnerships_contact',
  SWE_ONBOARDING: 'swe_onboarding',
  UNSUBSCRIBE: 'unsubscribe',
  UNSUBSCRIBED: 'unsubscribed',
  AUTOMATIC_COUPONS: 'automatic_coupons',
  UNSUBSCRIBE_TAG: 'unsubscribe_tag',
  MUTE_TAG: 'mute_tag',
  DATA_DELETED: 'data_deleted',
  PARTNERSHIPS: 'partnerships',
  ITEMS__EMPTY_STATE: 'items_empty_state',
  CASHBACK_PICKS__EMPTY_STATE: 'stores_empty_state',
  MODAL_LIST_CREATE: 'list_create',
  MODAL_LIST_DELETE: 'list_delete',
  MODAL_LIST_SHARE: 'list_share',
  MODAL_LIST_UPDATE: 'list_edit',
  MODAL_NOTIFICATION_MENU: 'notification_menu',
  MODAL_LOGIN: 'login',
  MODAL_SIGN_UP: 'sign_up',
  MODAL_COMPLETE_SIGN_UP: 'complete_sign_up',
  MODAL_CONGRATULATE_SIGN_UP: 'congratulate_sign_up',
  MODAL_RESET_PASSWORD: 'reset_password',
  MODAL_CHANGE_LANGUAGE: 'change_language',
  MODAL_PRODUCT: 'product',
  SIGN_UP_TO_CLAIM_BONUS: 'sign_up_to_claim_bonus',
  POPUP_AUTOCOMPLETE_SUGGESTIONS: 'search',
  NOTIFICATIONS: 'notifications',
  TAGS_DELETE: 'tags_delete',
  TAGS_FULFILL: 'tags_fulfill',
  TAGS_MUTE: 'tags_mute',
  TAGS_ADD_TO_LIST: 'tags_add_to_list',
  WITHDRAW_CONGRATULATIONS: 'withdraw_congratulations',
  UNDER_MAINTENANCE: 'under_maintenance',
  TAG_CREATE: 'tag_create',
  CONGRATS_GUEST: 'congrats_guest',
  WELCOME_BONUS: 'welcome_bonus',
  DO_NOT_SELL_MY_INFO: 'do_not_sell_my_info',
  VIDEO_HOW_TO_SAVE: 'video_how_to_save',
  PAYPAL_CONFIRM: 'paypal_confirm',
  PAYPAL_CONNECT: 'paypal_connect',
  PAYPAL_DISCONNECT: 'paypal_disconnect',
  ALERT: 'toaster',
  POP_UP_FOR_DELETE_ITEM: 'pop_up_for_delete_item',
  SUCCESS_NOTIFICATION_ALERT: 'save_item_via_URL',
  NOT_SUPPORTED_NOTIFICATION_ALERT: 'not_supported_via_URL',
  ITEM_SAVED: 'item_saved',
  COMEET_PRIVACY: 'comeet_privacy',
  GLOBAL_SEARCH_RESULTS: 'search_results',
  ONBOARDING_SAFARI_STEP_1: 'onboarding_safari_step_1',
  ONBOARDING_SAFARI_STEP_2: 'onboarding_safari_step_2',
  ONBOARDING_SAFARI_SIGN_UP: 'onboarding_safari_sign_up',
  ONBOARDING_V2_EXTENSION: 'onboarding_v2_extension',
  COOKIE_MANAGER: 'cookie_manager',
  DESKTOP_SWE_LOGIN: 'desktop_swe_login',
  ONBOARDING_EXTENSION_SIGNUP: 'onboarding_extension_signup',
};

export default SOURCE_PAGES;
