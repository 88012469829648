import { useState } from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_TAG } from 'consts/defaultImages';
import { StyledImage } from './TagImage.style';

const TagImage = ({ imageSrc, retailerSrc, objectFit, ...rest }) => {
  const [src, setSrc] = useState(imageSrc);

  const onErrorHandler = () => {
    if (retailerSrc && src !== retailerSrc) {
      setSrc(retailerSrc);

      return;
    }

    setSrc(DEFAULT_TAG);
  };

  return (
    <StyledImage
      src={src}
      onError={onErrorHandler}
      $objectFit={src === retailerSrc ? 'scale-down' : objectFit}
      {...rest}
    />
  );
};

TagImage.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  retailerSrc: PropTypes.string.isRequired,
  objectFit: PropTypes.oneOf(['fill', 'contain', 'cover', 'none', 'scale-down', 'initial']),
};

TagImage.defaultProps = {
  objectFit: 'cover',
};

export default TagImage;
