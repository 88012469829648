import { call, put } from 'redux-saga/effects';
import { COOKIES } from 'consts/cookies';
import logout from 'functions/authentication/logout';
import { getCookieValue } from 'functions/cookies';
import { handleServerError } from 'store/alerts/actions';
import { initBraze, setBrazeToken } from 'services/braze';
import retry from 'functions/retry';
import sendRollbarError from 'functions/sendRollbarError';
import * as userActions from '../actions';
import * as userApi from '../api';

const getUserWithRetry = () => retry(userApi.getUser, 5);

function* fetchUser() {
  try {
    if (getCookieValue(COOKIES.logged)) {
      const response = yield call(getUserWithRetry);

      setBrazeToken(response?.data?.braze_token);
      initBraze();
      yield put(userActions.saveUser(response?.data));
    } else {
      yield put(userActions.saveUser());
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(userActions.saveUser());
      logout();
    } else {
      yield put(handleServerError(error));
    }

    sendRollbarError('fetchUser issue', { error });
  }
}

export default fetchUser;
