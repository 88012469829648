import { call, put } from 'redux-saga/effects';
import { COOKIES } from 'consts/cookies';
import { ITEMS } from 'consts/localStorage';
import { HOUR_IN_MILLISECONDS } from 'consts/time';
import { setCookie } from 'functions/cookies';
import { handleServerError } from 'store/alerts/actions';
import retry from 'functions/retry';
import sendRollbarError from 'functions/sendRollbarError';
import * as featuresActions from '../actions';
import * as featuresApi from '../api';

function setFeaturesToLS(features) {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(ITEMS.features, JSON.stringify(features));

    const now = new Date();
    const time = now.getTime();
    const expireTime = time + 4 * HOUR_IN_MILLISECONDS;

    now.setTime(expireTime);
    setCookie(COOKIES.featuresUpToDate, true, now.toGMTString());
  }
}

const getFeaturesWithRetry = () => retry(featuresApi.getFeatures, 5);

function* fetchFeatures() {
  try {
    const response = yield call(getFeaturesWithRetry);

    if (!response?.data) throw new Error('/configuration/web/features response is corrupted');
    setFeaturesToLS(response.data);
    yield put(featuresActions.setFeatures(response.data));
  } catch (error) {
    yield put(featuresActions.setFeatures({}));
    yield put(handleServerError(error));
    sendRollbarError('fetchFeatures issue', { error });
  }
}

export default fetchFeatures;
