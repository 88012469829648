import asyncSleep from 'functions/asyncSleep';

const MAX_TIMEOUT = 500;

async function retryWorker(fn, attempts, attempt) {
  try {
    return await fn();
  } catch (error) {
    if (attempt >= attempts) throw error;

    const nextAttempt = attempt + 1;

    await asyncSleep((nextAttempt / attempts) * MAX_TIMEOUT);

    return retryWorker(fn, attempts, nextAttempt);
  }
}

function retry(fn, attempts) {
  if (typeof fn !== 'function' || typeof attempts !== 'number' || !attempts) return Promise.resolve(null);

  return retryWorker(fn, attempts, 1);
}

export default retry;
