import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import { fetchUser } from 'store/user/actions';
import { authenticateUser } from 'functions/authentication/authentication';
import sendRollbarError from 'functions/sendRollbarError';
import * as userApi from '../api';

function* loginWithTokenHandler({ payload }) {
  const { data } = payload;

  try {
    const response = yield call(userApi.loginWithToken, data);

    authenticateUser(response?.data);
    yield put(fetchUser());
  } catch (error) {
    yield put(handleServerError(error));
    sendRollbarError('loginWithTokenHandler issue', { error });
  }
}

export default loginWithTokenHandler;
