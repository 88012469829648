import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import { setPosition } from '../actions';
import { getPosition } from '../api';

function* fetchPosition({ payload }) {
  try {
    const response = yield call(getPosition, payload);

    yield put(setPosition(response.data || []));
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default fetchPosition;
