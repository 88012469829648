import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  positions: [],
  position: [],
  arePositionsLoaded: false,
  isPositionLoaded: false,
};

const openPositionsSlice = createSlice({
  name: 'openPositions',
  initialState,
  reducers: {
    setPositions: (state, { payload: positions }) => ({
      ...state,
      positions,
      arePositionsLoaded: true,
    }),
    setPosition: (state, { payload: position }) => ({
      ...state,
      position,
      isPositionLoaded: true,
    }),
  },
});

export default openPositionsSlice;
