import PropTypes from 'prop-types';
import { KarmaLogo } from '@karma/components';
import { DEFAULT_TAG } from 'consts/defaultImages';
import { StyledTagImage, EmptyHead, Stack, Tail } from './TagStack.style';

const TagStack = ({ headTag, multiple, ...rest }) => {
  return (
    <Stack {...rest}>
      {headTag?.main_img_url ? (
        <StyledTagImage
          imageSrc={headTag.main_img_url || headTag.retailer_logo || DEFAULT_TAG}
          retailerSrc={headTag.retailer_logo}
        />
      ) : (
        <EmptyHead>
          <KarmaLogo variant="symbol" width={40} />
        </EmptyHead>
      )}
      {multiple && <Tail />}
    </Stack>
  );
};

TagStack.propTypes = {
  headTag: PropTypes.shape({
    main_img_url: PropTypes.string.isRequired,
    retailer_logo: PropTypes.string.isRequired,
  }).isRequired,
  multiple: PropTypes.oneOf([PropTypes.number, PropTypes.bool]),
};

TagStack.defaultProps = {
  multiple: true,
};

export default TagStack;
