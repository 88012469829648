import styled, { css } from 'styled-components';
import { getColor } from '@karma/components/colors';
import TagImage from 'components/atoms/TagImage/TagImage';

const HeadStyle = css`
  position: relative;
  width: 120px;
  height: 120px;
  background-color: ${getColor('primary2')};
  border: 1px solid ${getColor('primary2')};
  border-radius: 15px;
  z-index: 1;
  box-sizing: content-box;
`;

export const StyledTagImage = styled(TagImage)`
  ${HeadStyle};
`;

export const EmptyHead = styled.div`
  ${HeadStyle};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Stack = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
`;

export const Tail = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%) rotate(-15deg);
  background: ${getColor('neutral4')};
  width: 120px;
  height: 120px;
  border-radius: 10px;
`;
