import styled, { css } from 'styled-components';
import { Modal } from 'react-bootstrap';
import { getColor } from '@karma/components/colors';
import { MAX_TABLET_WIDTH } from 'consts/common';

const modalW400 = css`
  & .modal-dialog {
    position: absolute;
    max-width: 400px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0;
  }

  & .modal-content {
    border-radius: 10px;
    background: ${getColor('neutral6')};
  }
`;

export const BootstrapModal = styled(Modal)`
  color: ${getColor('primary1')};

  .modal-dialog {
    width: 470px;
    margin-top: 90px;

    @media (max-width: 470px) {
      width: calc(100% - 1rem);
      margin-top: 90px;
    }
  }

  & .modal-content {
    min-height: 200px;
    padding: 40px;
    border: none;
    border-radius: 20px;
    box-shadow: none;
  }
`;

export const MixinModalMyItems = styled(Modal)`
  ${modalW400}
`;

export const MixinModalUnderMaintenance = styled(Modal)`
  ${modalW400};
`;

export const MixinModalItemSaved = styled(Modal)`
  ${modalW400}
`;

export const MixinModalPartnershipsContact = styled(Modal)`
  & .modal-dialog {
    width: auto;
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: ${MAX_TABLET_WIDTH}px) {
      width: auto;
      max-width: 335px;
    }
  }

  & .modal-content {
    padding: 40px 70px;
    @media (max-width: ${MAX_TABLET_WIDTH}px) {
      padding: 20px;
    }
  }
`;

export const MixinModalLogInLanding = styled(Modal)`
  & .modal-dialog {
    width: 480px;
  }

  & .modal-content {
    padding: 40px;
  }

  & .modal-body {
    display: flex;
    flex-direction: column;
  }
`;

export const MixinModalBonusWelcome = styled(Modal)`
  ${modalW400}
`;

export const MixinModalOutdatedOnboarding = styled(Modal)`
  ${modalW400}
`;

export const MixinModalPaypal = styled(Modal)`
  ${modalW400}
  & .modal-content {
    padding: 40px 32px;
  }
`;

export const MixinModalVideoHowToSave = styled(Modal)`
  & .modal-dialog {
    width: 80%;
    max-width: 1200px;
    margin-top: 50px;

    @media (min-aspect-ratio: 5/3) and (max-width: 1500px) {
      margin-top: 30px;
      width: 70%;
    }

    button[name='close'] {
      top: 10px;
      right: 10px;
    }
  }
`;

export const MixinModalWithdraw = styled(Modal)`
  text-align: center;

  & .modal-dialog {
    position: absolute;
    top: calc(50% - 230px);
    left: calc(50% - 200px);
    margin: 5px;
    max-width: 400px;
    margin-top: 0;
  }

  & .modal-content {
    border-radius: 10px;
  }
`;

export const MixinModalDoNotSellMyInfo = styled(Modal)`
  & .modal-dialog {
    width: auto;
    max-width: 530px;
  }

  & .modal-content {
    padding: 80px;
  }

  & h3[name='title'] {
    margin-bottom: 40px;
    font-size: 30px;
    line-height: 1.33;

    @media (max-width: 767px) {
      max-width: 90%;
    }
  }
`;
export const MixinModalChangeLanguage = styled(Modal)`
  & .modal-dialog {
    width: 480px;
  }
  & h3[name='title'] {
    margin-bottom: 40px;
  }
`;

export const MixinModalProduct = styled(Modal)`
  & .modal-dialog {
    min-width: 780px;
  }

  & .modal-content {
    padding: 40px;
  }
`;

export const MixinModalAuthenticate = styled(Modal)`
  & .modal-dialog {
    width: 840px;
    max-width: 100%;
  }

  & .modal-content {
    padding: 0;
  }
`;

export const MixinModalChatWithUs = styled(Modal)`
  & .modal-dialog {
    width: 816px;
    max-width: 100%;
  }

  & .modal-content {
    padding: 0;
  }
`;

export const MixinModalAuthenticateSmall = styled(Modal)`
  & .modal-dialog {
    width: 500px;
    max-width: 100%;
  }

  & .modal-content {
    padding: 0;
  }
`;

export const MixinModalResetPassword = styled(Modal)`
  & .modal-dialog {
    width: 840px;
    max-width: 100%;

    @media (max-width: 767px) {
      width: 96%;
    }
  }

  & .modal-content {
    padding: 0;
  }
`;

export const MixinModalStoreInfo = styled(Modal)`
  overflow: hidden !important;

  & .modal {
    padding: 0;
  }

  & .modal-dialog {
    width: 1100px;
    max-width: 90%;
    height: 100%;
    padding: 30px 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  & .modal-content {
    background: ${getColor('neutral6')};
    max-height: 90vh;
    height: fit-content;
    overflow: hidden;
    padding: 0;
  }

  .modal-body {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 40px;

    &::-webkit-scrollbar {
      border-radius: 8px;
      width: 4px;
      background: transparent;

      &:hover {
        width: 8px;
        background: transparent;
      }
    }

    &::-webkit-scrollbar-track {
      border-radius: 50%;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: ${getColor('neutral4')};
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${getColor('neutral4')};
    }
  }
`;

export const MixinModalManageStores = styled(Modal)`
  & .modal-dialog {
    width: 640px;
    max-width: 100%;
  }

  & .modal-content {
    padding: 32px;
    background: ${getColor('neutral6')};
  }
`;

export const MixinModalDeleteAccount = styled(Modal)`
  & .modal-dialog {
    width: 600px;
    max-width: 100%;
  }

  & .modal-content {
    padding: 40px;
    background: ${getColor('neutral6')};
  }
`;
