import { careersApiInstance } from 'functions/request';
import { COMEET_UID, COMEET_TOKEN } from 'consts/comeet';

export const getPositions = () => {
  return careersApiInstance.get(`/company/${COMEET_UID}/positions?token=${COMEET_TOKEN}&details=true`);
};

export const getPosition = uid => {
  return careersApiInstance.get(`/company/${COMEET_UID}/positions/${uid}?token=${COMEET_TOKEN}&details=true`);
};
